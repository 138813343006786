import React, {useEffect, useRef, useState} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../assets/ts/_utils'
import {useThemeMode} from '../../layout/theme-mode/ThemeModeProvider'
import moment from "moment-timezone"
import {SecurityType} from '@metronic/Enums/Securities/SecurityType'

type Props = {
  className: string
  data: any
  tickerType: string
  selectedSecurities: object
  // setRangeHandler: any
  tickerRange: string
}

const ChartsWidget3: React.FC<Props> = ({className, data, tickerType, selectedSecurities,  tickerRange}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const [datesOptions, setDatesOptions] = useState([])
  const [seriesOptions, setSeriesOptions] = useState([])
  const [valuesOptions, setValuesOptions] = useState([])
  const [isLoading, setIsLoading] = useState(false)
  const [mapData, setMapData] = useState([])

  useEffect(() => {
    if (tickerType === SecurityType.STOCK && data['stock_data']) {
      setMapData(data['stock_data'])
    } else if (data && tickerType === SecurityType.INDEX && data['index_data']) {
      setMapData(data['index_data'])
    }
  }, [data])

  useEffect(() => {
    if (mapData?.length) {

      const newDatesOptions = mapData.map(item => {
        if (tickerRange === 'month') {
          return moment.tz(
            tickerType === SecurityType.INDEX ? item.data.date : item.data.t, 'UTC'
          ).format('MMM, yy')
        }
        if (tickerRange === 'year') {
          return moment.tz(
            tickerType === SecurityType.INDEX ? item.data.date : item.data.t, 'UTC'
          ).format('YYYY')
        }
      })

      const newSeriesOptions = mapData.map(item => {
        return tickerType === SecurityType.INDEX
          ? Number(item.data.close)
          : ((Number(item.data.h) + Number(item.data.l)) / 2).toFixed(2)
      })

      const newValuesOptions = mapData.map(item => {
        return tickerType === SecurityType.INDEX
          ? {close: Number(item.data.close)}
          : {max: item.data.h, min: item.data.l, open: item.data.o, close: item.data.c}
      })

      setDatesOptions(newDatesOptions)
      setSeriesOptions(newSeriesOptions)
      setValuesOptions(newValuesOptions)
      setIsLoading(false)
    } else {
      setDatesOptions([])
      setSeriesOptions([])
      setValuesOptions([])
      setIsLoading(false)
    }
  }, [tickerType, selectedSecurities, mapData])

  const refreshMode = () => {
    if (!chartRef.current) {
      return
    }

    const chart = new ApexCharts(chartRef.current, getChartOptions(datesOptions, seriesOptions, valuesOptions, tickerType))
    if (chart) {
      chart.render()
    }

    return chart
  }

  useEffect(() => {
    const chart = refreshMode()

    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [chartRef, mode, datesOptions, tickerType])

  return (
    (seriesOptions && seriesOptions.length) ?
    <div>
      <div className="d-flex justify-content-between">
        <h4 className="text-gray-700 border-bottom-dashed border-1 border-gray-300 pb-1 w-100">OHLC Chart</h4>
        {/*<div className="d-flex">*/}
        {/*  <a*/}
        {/*    className={`btn btn-sm btn-color-muted py-1 btn-light-primary px-4 me-1*/}
        {/*    ${tickerRange === 'year' && 'active'}`}*/}
        {/*    onClick={() => setRangeHandler('year')}*/}
        {/*  >*/}
        {/*    Year*/}
        {/*  </a>*/}

        {/*  <a*/}
        {/*    className={`btn btn-sm btn-color-muted py-1 btn-light-primary px-4 me-1*/}
        {/*    ${tickerRange === 'month' && 'active'}`}*/}
        {/*    onClick={() => setRangeHandler('month')}*/}
        {/*  >*/}
        {/*    Month*/}
        {/*  </a>*/}
        {/*</div>*/}
      </div>

      <div className="overflow-hidden" ref={chartRef} id='kt_charts_widget_3_chart' style={{height: '350px'}}></div>
    </div> : null

    // <div className={`card ${className}`}>
    //   {/* begin::Header */}
    //   <div className='card-header border-0 pt-5'>
    //   {/*  <h3 className='card-title align-items-start flex-column'>*/}
    //       {/*<span className='card-label fw-bold fs-3 mb-1'>"{name}" rates</span>*/}
    //
    //       {/*<span className='text-muted fw-semibold fs-7'>More than 1000 new records</span>*/}
    //     {/*</h3>*/}
    //
    //      {/*begin::Toolbar */}
    //
    //     {/* end::Toolbar */}
    //   </div>
    //   {/* end::Header */}
    //
    //   {/* begin::Body */}
    //   <div className='card-body'>
    //     {/* begin::Chart */}
    //     {/* end::Chart */}
    //   </div>
    //   {/* end::Body */}
    // </div>
  )
}

export {ChartsWidget3}

function getChartOptions(datesOptions, seriesOptions, valuesOptions, tickerType): ApexOptions {
  const labelColor = getCSSVariableValue('--bs-gray-500')
  const borderColor = getCSSVariableValue('--bs-gray-200')
  const baseColor = getCSSVariableValue('--bs-info')
  const lightColor = getCSSVariableValue('--bs-info-light')

  return {
    series: [
      {
        name: 'Values',
        data: [...seriesOptions],
      },
    ],
    chart: {
      fontFamily: 'inherit',
      type: 'area',
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {},
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    fill: {
      type: 'solid',
      opacity: 1,
    },
    stroke: {
      curve: 'smooth',
      show: true,
      width: 3,
      colors: [baseColor],
    },
    xaxis: {
      categories: [...datesOptions],
      tickPlacement: 'between',
      tickAmount: 'dataPoints',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
      crosshairs: {
        position: 'front',
        stroke: {
          color: baseColor,
          width: 1,
          dashArray: 3,
        },
      },
      tooltip: {
        enabled: true,
        formatter: undefined,
        offsetY: 0,
        style: {
          fontSize: '12px',
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: labelColor,
          fontSize: '12px',
        },
      },
    },
    states: {
      normal: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      hover: {
        filter: {
          type: 'none',
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: 'none',
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: '12px',
      },
      y: {
        formatter: function (val) {
          if (tickerType === SecurityType.INDEX) {
            const range = valuesOptions.find(i => Number(i.close) === Number(val))
            return range ? `Close: $${range.close}` : `Close: $${val}`
          } else {
            const range = valuesOptions.find(i => {
              const sum = ((Number(i.max) + Number(i.min)) / 2).toFixed(2)
              return sum === Number(val).toFixed(2)
            })
            return range ?
              `Max: $${range.max}, Min: $${range.min}, Open: $${range.open}, Close: $${range.close}` :
              `Value: $${val}`
          }
        },
      },
    },
    colors: [lightColor],
    grid: {
      borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
    markers: {
      strokeColors: baseColor,
      strokeWidth: 3,
    },
  }
}
