
import React, {FC, useEffect, useState} from 'react'
import {Link, router, usePage} from '@inertiajs/react'
// import {useAuth} from '@metronic/modules/auth'
// import {Languages} from '@metronic/_metronic/partials/layout/header-menus/Languages'
// import {KTIcon, toAbsoluteUrl} from '@metronic/_metronic/helpers'
// import {Tooltip} from "react-tooltip"
// Default Avatar
import defaultAvatar from '@metronic/assets/images/users/user-dummy-img.jpg'
import {Dropdown, DropdownMenu, DropdownToggle} from "reactstrap"
import clsx from "clsx"

const HeaderUserMenu: FC = () => {
  const {props: pageProps} = usePage()
  const userData = pageProps.auth.user
  const [isDropdown, setIsDropdown] = useState(false)
  const [isProfileActive, setIsProfileActive] = useState(false)
  const [isSignOutActive, setIsSignOutActive] = useState(false)

  const logout = () => {
    router.post('/logout')
  }

  const toggleDropdown = () => {
    setIsProfileActive(false)
    setIsDropdown(!isDropdown)
  }

  return (
    // <div
    //   className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
    //   data-kt-menu='true'
    // >
    <Dropdown
      isOpen={isDropdown}
      toggle={toggleDropdown}
    >
      <DropdownToggle
        type="button"
        tag="button"
        className="btn btn-icon"
      >
        <img
          className='h-30px w-30px rounded object-fit-cover'
          src={pageProps?.auth?.user?.profile?.avatar_url ? pageProps?.auth?.user?.profile?.avatar_url : defaultAvatar}
          alt='avatar'
        />
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-lg py-0">
        <div className="menu-item px-3">
          <div className="menu-content d-flex align-items-center px-3">
            <div className="symbol symbol-50px me-5">
              <img className="object-fit-cover" alt='Logo'
                   src={userData?.profile?.avatar_url ? userData?.profile?.avatar_url : defaultAvatar}/>
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bold d-flex fs-5'>
                {userData?.name}
              </div>
              <span className='fw-semibold text-muted fs-7'>
              {userData?.email}
            </span>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <Link
            href={'/profile'}
            onClick={toggleDropdown}
            className={clsx(`menu-link px-5`, isProfileActive ? 'bg-light-dark rounded-2' : '')}
            onMouseEnter={() => setIsProfileActive(true)}
            onMouseLeave={() => setIsProfileActive(false)}
          >
            My Profile
          </Link>
        </div>

        {/*<div className='menu-item px-5'>*/}
        {/*  <a href='#' className='menu-link px-5'>*/}
        {/*    <span className='menu-text'>My Projects</span>*/}
        {/*    <span className='menu-badge'>*/}
        {/*      <span className='badge badge-light-danger badge-circle fw-bold fs-7'>3</span>*/}
        {/*    </span>*/}
        {/*  </a>*/}
        {/*</div>*/}

        {/*<div*/}
        {/*  className='menu-item px-5'*/}
        {/*  data-kt-menu-trigger='hover'*/}
        {/*  data-kt-menu-placement='left-start'*/}
        {/*  data-kt-menu-flip='bottom'*/}
        {/*>*/}
        {/*  <a href='#' className='menu-link px-5'>*/}
        {/*    <span className='menu-title'>My Subscription</span>*/}
        {/*    <span className='menu-arrow'></span>*/}
        {/*  </a>*/}

        {/*  <div className='menu-sub menu-sub-dropdown w-175px py-4'>*/}
        {/*    <div className='menu-item px-3'>*/}
        {/*      <a href='#' className='menu-link px-5'>*/}
        {/*        Referrals*/}
        {/*      </a>*/}
        {/*    </div>*/}

        {/*    <div className='menu-item px-3'>*/}
        {/*      <a href='#' className='menu-link px-5'>*/}
        {/*        Billing*/}
        {/*      </a>*/}
        {/*    </div>*/}

        {/*    <div className='menu-item px-3'>*/}
        {/*      <a href='#' className='menu-link px-5'>*/}
        {/*        Payments*/}
        {/*      </a>*/}
        {/*    </div>*/}

        {/*    <div className='menu-item px-3'>*/}
        {/*      <a href='#' className='menu-link d-flex flex-stack px-5'>*/}
        {/*        Statements*/}
        {/*        <i*/}
        {/*          className='fas fa-exclamation-circle ms-2 fs-7'*/}
        {/*          data-bs-toggle='tooltip'*/}
        {/*          title='View your statements'*/}
        {/*        ></i>*/}
        {/*      </a>*/}
        {/*    </div>*/}

        {/*    <div className='separator my-2'></div>*/}

        {/*    <div className='menu-item px-3'>*/}
        {/*      <div className='menu-content px-3'>*/}
        {/*        <label className='form-check form-switch form-check-custom form-check-solid'>*/}
        {/*          <input*/}
        {/*            className='form-check-input w-30px h-20px'*/}
        {/*            type='checkbox'*/}
        {/*            value='1'*/}
        {/*            defaultChecked={true}*/}
        {/*            name='notifications'*/}
        {/*          />*/}
        {/*          <span className='form-check-label text-muted fs-7'>Notifications</span>*/}
        {/*        </label>*/}
        {/*      </div>*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}

        {/*<div className='menu-item px-5'>*/}
        {/*  <a href='#' className='menu-link px-5'>*/}
        {/*    My Statements*/}
        {/*  </a>*/}
        {/*</div>*/}

        <div className='separator my-2'></div>

        {/*<Languages />*/}

        {/*<div className='menu-item px-5 my-1'>*/}
        {/*  <Link href='/crafted/account/settings' className='menu-link px-5'>*/}
        {/*    Account Settings*/}
        {/*  </Link>*/}
        {/*</div>*/}

        <div className='menu-item px-5 pb-3'>
          <a
            onClick={logout}
            className={clsx(`menu-link px-5`, isSignOutActive ? 'bg-light-dark rounded-2' : '')}
            onMouseEnter={() => setIsSignOutActive(true)}
            onMouseLeave={() => setIsSignOutActive(false)}
          >
            Sign Out
          </a>
        </div>

      </DropdownMenu>
    </Dropdown>
    // </div>
  )
}

export {HeaderUserMenu}
